import './App.css';
import logo from'./assets/logo short.png'

function App() {
  return (
    <>
    <div className={`App bgc${Math.floor(Math.random()*3)}`}>
      <img className='logo' src={logo}/>
      <h1>We are still building the future of trucking</h1>
    </div>
    </>
  );
}

export default App;
